import configureForm from '@helpers/form';

const Selector = {
  JS_FORM: 'js-sign-in-form',
  FORGOT_PASSWORD_FORM: 'forgot-password',
};

const InputName = {
  EMAIL: 'session[email_address]',
  PASSWORD: 'session[password]',
};

const addValidation = (component) => {
  configureForm(component, {
    rules: {
      [InputName.EMAIL]: {
        required: true,
        email: true,
        pattern: '(.+)@(.+){2,}.{1}(.+){2,}',
      },
      [InputName.PASSWORD]: {
        required: true,
        password: false,
      },
    },
    messages: {
      [InputName.EMAIL]: {
        required: 'Please enter a valid email address',
        email: 'Please enter a valid email address',
        pattern: 'Please enter a valid email address',
      },
      [InputName.PASSWORD]: {
        required: 'Please enter password.',
      },
    },
  });
};

const toggleForgotPasswordForm = () => {
  $(`.${Selector.FORGOT_PASSWORD_FORM}`).click(() => {
    const elements = [
      'div.user-form__header',
      'form#login-form',
      'section.password',
    ];

    elements.forEach((tag) => $(tag).toggle('fold'));
  });
};

const initSignInForm = () => {
  const component = document.querySelector(`.${Selector.JS_FORM}`);
  addValidation(component);
  toggleForgotPasswordForm();
};

export { initSignInForm };
